/* eslint-disable import/order */
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './components'
import { theme } from './styles/theme'
import { ToastContainer } from 'react-toastify'
import { ApolloProvider } from '@apollo/client'
import { Analytics } from 'components/Analytics'
import { BrowserRouter } from 'react-router-dom'
import { persistor, store } from './redux/store'
import 'react-datepicker/dist/react-datepicker.css'
import createApolloClient from 'helpers/apolloClient'
import { Provider as ReduxProvider } from 'react-redux'
import { GlobalStyles } from './assets/styles/globalStyles'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { RoleProvider } from 'helpers/CheckUserRoleAccess/CheckUserRoleAccess'

export const client = createApolloClient({})

ReactDOM.render(
  <ApolloProvider client={client}>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <MUIThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <BrowserRouter>
              <RoleProvider>
                <App />
              </RoleProvider>
              <GlobalStyles />
            </BrowserRouter>
          </StyledThemeProvider>
        </MUIThemeProvider>
      </PersistGate>
    </ReduxProvider>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
    <Analytics />
  </ApolloProvider>,
  document.getElementById('root')
)
